.language-picker {

    cursor: pointer;
    display: none;
    float: right;
    position: relative;
    width: 140px;

    &__container {
        position: relative;
        z-index: $z-index--5;
    }

    &__dropdown {
        background-color: $color--white;
        box-shadow: 0 1px 0 1px rgba($color--black, .1);
        display: none;
        left: 0;
        list-style: none;
        margin-left: 0;
        position: absolute;
        top: 30px;
        width: 100%;

        .language-picker:hover & {
            display: block;
            opacity: 1;
        }
    }

    &__link {
        color: $color--blue;
        display: block;
        font-size: $font-size--xs;
        font-weight: 500;
        letter-spacing: 1.5px;
        opacity: .8;
        padding: 9px 30px 9px 10px;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            opacity: 1;
        }

        .language-picker__item--selected & {
            color: rgba($color--white, .8);
            opacity: 1;
        }
    }

    &__item--selected {
        display: block;
        position: relative;

        &:after {
            @extend .bg--arrow-down-white;
            background-position: center;
            bottom: 0;
            content: '';
            height: 100%;
            position: absolute;
            right: 13px;
            width: 20px;
        }

        .language-picker:hover & {
            background-color: rgba($color--white, .2);
        }
    }
}

@include media--md {
    .language-picker {
        display: block;
    }
}
