@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);

.suggestions {    

    textarea {
        width: 100%;
        resize: none;
        height: 130px;
        border: 2px $color--medium-gray solid;
        border-radius: 4px;
        margin-top: 10px;
        padding: 10px;
        font-family: MuseoSans;
        font-size: $font-size--md;   
        color: $color--gray;
        line-height: 22px;     
        font-weight: 300;
        overflow: hidden;        
    }   
    
    .float-right {
        float: right;
    }  

    input {        
        width: 100%;        
        position: relative;
        padding: 0px;
        margin: 0 auto;        
    }    

    .loading {
        text-align: center;  
        font-size:0;
        width:30px;
        height:30px;
        margin-top:5px;
        border-radius:15px;
        padding:0;
        border:3px solid $color--black-100;
        border-bottom:3px solid rgba(255,255,255,0.0);
        border-left:3px solid rgba(255,255,255,0.0);
        background-color:transparent !important;
        -webkit-animation: rotation 2s infinite linear;
        animation: rotation 2s infinite linear;
        animation-name: rotateAnimation;
        -webkit-animation-name: wk-rotateAnimation;
        animation-duration: 1s;
        -webkit-animation-duration: 1s;
        animation-delay: 0.2s;
        -webkit-animation-delay: 0.2s;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
    }

    @keyframes rotateAnimation {
        0%   {transform: rotate(0deg);}
        100% {transform: rotate(360deg);}
    }
    @-webkit-keyframes wk-rotateAnimation {
        0%   {-webkit-transform: rotate(0deg);}
        100% {-webkit-transform: rotate(360deg);}
    }

    .fa {   
        display: none;             
        font-size:18px !important;                
        -webkit-transform:scaleX(0) !important;
        transform:scaleX(0) !important;                        
        width: 100%;     
        text-align: center;  
        height: 46px;
        line-height: 44px;
    }

    .fa-check {
        color: $color--green;        
    }

    .fa-close {
        color: $color--red;
    }

    .finish {
        -webkit-transform: scaleX(1) !important;
        transform: scaleX(1) !important;
        display: inline-block;
    }

    .hide-loading {
        opacity:0;
        -webkit-transform: rotate(0deg) !important;
        transform: rotate(0deg) !important;
        -webkit-transform:scale(0) !important;
        transform:scale(0) !important;
    }

    .ajax-button {
        margin-top: 25px;
        width: 100%;          
    }
}

.suggestions__subheader {    
    padding-top: 30px;
    color: $color--black-100;
}

@include media--md { 
    .suggestions {
        .float-right {
            float: right;
        } 
        
        input {
            display: block;            
            width: auto;
        }     
        
        .ajax-button {
            margin-top: 25px;
            width: 167px;       
            margin-right: 0px; 
        }  
    }
}