.usp {
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: $z-index--usp;

    &--open {
        visibility: visible;
    }

    &--fade {
        animation: hide .4s forwards;
    }

    &__container {
        background-color: $color--white;
        border: 1px solid $color--light-gray;
        padding: 50px 10px 30px;
        position: relative;
        text-align: center;
        width: calc(100% - 20px);
    }

    &__title,
    h2 {
        font-size: $font-size--xl;
        margin-bottom: 20px;
    }

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

@include media--md {

    .usp {

        align-items: center;
        background-color: rgba($color--white, .8);
        display: flex;
        flex-flow: column;
        height: 100%;
        justify-content: center;
        padding-top: 50px;
        position: fixed;
        top: 0;

        &::before {
            background-color: rgba($color--white, .8);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: -100%;
            width: 100%;
        }

        &--sticky {
            position: absolute;
        }

        &__container {
            padding: 12% 10%;
            width: 80%;
        }

        &__title,
        h2 {
            font-size: $font-size--xxxl;
            margin-bottom: 45px;
        }

        &__text,
        p {
            font-size: $font-size--lg;
        }

        &__close {
            right: 40px;
            top: 40px;
        }
    }
}
