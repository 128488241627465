// Clear floats
.u-clearfix {
    zoom: 1;

    &:before {
        content: '';
        display: block;
    }

    &:after {
        clear: both;
        content: '';
        display: table;
    }
}

// Grid container
.u-flex {
    display: flex;
    flex-flow: wrap;
}

// Use when you want gutter
.u-flex--g {
    justify-content: space-between;
}

.u-confine {
    margin: 0 auto;
    padding-left: $padding;
    padding-right: $padding;
}

@include media--md {
    .u-confine--md {
        max-width: $width--md + $padding--md * 2;
        padding-left: $padding--md;
        padding-right: $padding--md;
    }
}

@include media--lg {
    .u-confine--lg {
        max-width: $width--lg + $padding--lg * 2;
        padding-left: $padding--lg;
        padding-right: $padding--lg;
    }
}

@include media--xl {
    .u-confine--xl {
        max-width: $width--max + $padding--xl * 2;
        padding-left: $padding--xl;
        padding-right: $padding--xl;
    }
}

// .u-middle {
//     &:before {
//         content: '';
//         display: inline-block;
//         height: 100%;
//         vertical-align: middle;
//         width: .1px;
//     }
// }
