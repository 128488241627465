.tooltip {

    position: relative;

    &::before {
        background-color: $color--dark-blue;
        bottom: 33px;
        content: '';
        display: block;
        height: 10px;
        position: absolute;
        right: 8px;
        transform: rotate(45deg);
        transition: opacity 200ms;
        visibility: hidden;
        width: 10px;
    }

    &::after {
        background-color: $color--dark-blue;
        border-radius: 4px;
        bottom: 38px;
        color: $color--white;
        content: attr(data-text);
        display: block;
        font-size: $font-size--xs;
        line-height: 1.6;
        padding: 10px;
        position: absolute;
        right: -20px;
        transition: opacity 200ms;
        visibility: hidden;
        width: 250px;
    }

    &--under {

        &::before {
            bottom: -19px;
        }

        &::after {
            bottom: auto;
            top: 40px;
        }
    }

    &--active::before,
    &--active::after {
        visibility: visible;
    }
}

@include media--md {

    .tooltip {

        &::after {
            right: -40px;
        }
    }
}
