.list {
    list-style: none;
    margin-left: 0;

    &__item {
        font-size: $font-size--lg;
    }
}

@include media--md {

    .list {
        margin-left: 20px;
    }
}
