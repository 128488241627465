.bg {

    &--logo {
        background-image: url(#{$path--img}logo-rikskuponger.svg);
        background-repeat: no-repeat;
    }

    &--logo-mono {
        background-image: url(#{$path--img}logo-rikskuponger-mono.svg);
        background-repeat: no-repeat;
    }

    &--logo-mono-white {
        background-image: url(#{$path--img}logo-rikskuponger-mono-white.svg);
        background-repeat: no-repeat;
    }

    &--nav-handle {
        background-image: url(#{$path--img}nav-handle.svg);
        background-repeat: no-repeat;
    }

    &--nav-close {
        background-image: url(#{$path--img}nav-close.svg);
        background-repeat: no-repeat;
    }

    &--arrow-down-white {
        background-image: url(#{$path--img}arrow-down-white.svg);
        background-repeat: no-repeat;
    }

    &--arrow-down-blue {
        background-image: url(#{$path--img}arrow-down-blue.svg);
        background-repeat: no-repeat;
    }

    &--arrow-indicator {
        background-image: url(#{$path--img}arrow-indicator.svg);
        background-repeat: no-repeat;
    }

    &--checkbox {
        background-image: url(#{$path--img}checkbox.svg);
        background-repeat: no-repeat;
    }

    &--checkbox-checked {
        background-image: url(#{$path--img}checkbox-checked.svg);
        background-repeat: no-repeat;
    }

    &--radio {
        background-image: url(#{$path--img}radio.svg);
        background-repeat: no-repeat;
    }

    &--radio-checked {
        background-image: url(#{$path--img}radio-checked.svg);
        background-repeat: no-repeat;
    }

    &--check-green {
        background-image: url(#{$path--img}check-green.svg);
        background-repeat: no-repeat;
    }

    &--cross-red {
        background-image: url(#{$path--img}cross-red.svg);
        background-repeat: no-repeat;
    }

    &--contest-airplane {
        background-image: url(#{$path--img}contest-airplane.svg);
        background-repeat: no-repeat;
    }

    &--transactions-calendar {
        background-image: url(#{$path--img}calendar-purple.svg);
        background-repeat: no-repeat;
        background-position: right 6px center; 
        background-size: 25px;             
    }
}
