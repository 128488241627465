@keyframes hide {

    from {
        opacity: 1;
        visibility: visible;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes loader-stretch {

    0%,
    40%,
    100% {
        transform: scaleY(.4);
    }

    20% {
        transform: scaleY(1);
    }
}
