@mixin create-grid($base, $modifier: '') {
    $index: $base;
    @while $index > 0 {
        $gcd: gcd($base, $index);
        $width: percentage($index / $base);

        .u-g-#{$index / $gcd}-#{$base / $gcd}#{$modifier} {
            // flex: 0 1 auto;
            // flex-basis: $width;
            width: $width;
        }

        $index: $index - 1;
    }
}

@mixin create-grid-with-gutter($base, $modifier: '') {
    $index: $base;
    @while $index > 0 {
        $gcd: gcd($base, $index);
        $width: $index / $base;
        $percentage: percentage($index / $base);
        $gutter: '20px';

        .u-g-g-#{$index / $gcd}-#{$base / $gcd}#{$modifier} {
            // flex: 0 1 auto;
            // flex-basis: calc(#{$percentage} - (#{$gutter} - #{$gutter} * #{$width}));
            width: calc(#{$percentage} - (#{$gutter} - #{$gutter} * #{$width}));
        }

        $index: $index - 1;
    }
}

@mixin grid($width, $gutter: 0) {
    $percentage: percentage($width);
    flex: 0 1 auto;
    @if ($gutter == 0) {
        width: #{$percentage};
    } @else {
        width: calc(#{$percentage} - (#{$gutter} - #{$gutter} * #{$width}));
    }
}

@mixin media--xs {
    @media only screen and (min-width: #{$width--xs}) {
        @content;
    }
}

@mixin media--sm {
    @media only screen and (min-width: #{$width--sm}) {
        @content;
    }
}

@mixin media--md {
    @media only screen and (min-width: #{$width--md}) {
        @content;
    }
}

@mixin media--lg {
    @media only screen and (min-width: #{$width--lg}) {
        @content;
    }
}

@mixin media--xl {
    @media only screen and (min-width: #{$width--xl}) {
        @content;
    }
}
