.header-main {

    background-color: $color--white;
    height: $height--mobile-header;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index--header-main;

    .page--index &, .page--contest & {
        background-color: transparent;
    }

    &__logo-container {
        display: inline-block;
        margin-top: 8px;
        position: relative;
        z-index: $z-index--header-main-logo;
    }

    &__bar {
        background-color: $color--white;
        position: relative;
        z-index: 999;

        &:before,
        &:after {
            background-color: $color--white;
            content: '';
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &:before {
            left: -100%;
        }

        &:after {
            right: -100%;
        }
    }

    &__buttons {
        border-bottom: 1px solid $color--black-100;
        padding: 18px $padding;
    }

    &__select {
        margin-bottom: 20px;
    }

    &__select--desktop {
        display: none;
    }

    &__select--mobile {
        margin-top: 20px;
    }

    &__login {
        color: $color--black-100;
    }
}

@include media--md {
    .header-main {
        height: auto;
        min-height: $height--desktop-header - 10;
        // overflow-x: hidden;
        padding-top: 30px;
        position: relative;

        .page--index &, .page--contest {
            min-height: auto;
        }

        &__logo-container {
            position: absolute;
            top: 17px;
        }

        &__bar {
            .page--index &, .page--contest & {
                display: none;
            }
        }

        &__buttons {
            border-bottom: none;
            float: right;
            padding: 0;
            position: relative;
            top: 21px;
        }

        &__select {
            margin-bottom: 0;
        }

        &__select--desktop {
            display: inline-block;
        }

        &__select--mobile {
            display: none;
        }

        &__login {
            margin-left: 25px;
        }
    }
}

@include media--lg {

    .header-main {

        min-height: $height--desktop-header;
    }
}
