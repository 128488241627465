.nav-sub {
    box-shadow: inset 0 2px 0 0 #cccccc;
    font-weight: 300;
    padding: 15px 0;

    &__link {
        color: $color--black-100;
        display: block;
        font-size: 1.7rem;
        font-weight: 300;
        line-height: 1.6;
        margin: auto $padding;
        text-decoration: none;

        &--active {
            color: $color--red;
        }

        &:hover {
            color: $color--black-90;
            text-decoration: none;
        }

    }
}

@include media--md {

    .nav-sub {
        // padding: 18px 0 18px 100px;
        height: 0;
        overflow: hidden;
        padding: 0;
        transition: all .2s;

        .settings-open & {
            // border-top: 1px solid $color--light-gray;
            height: 65px;
            padding: 18px 0;
        }

        &__link {
            color: $color--black-60;
            display: inline;
            margin: 0 20px 0 0;

            &--active {
                color: $color--red;
                // font-weight: 500;
                text-decoration: none;
            }
        }
    }
}
