.nav-container {

    background-color: $color--white;
    display: none;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: $z-index--nav-container;

    .menu-open & {
        display: block;
        min-height: 100vh;
    }

    &__inner-container {
        position: relative;
    }

    &__outer-container {
        background-color: inherit;
        width: 100%;
    }

    &__mobile {
        height: calc(100% - 90px);
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
        position: fixed;
        width: 100%;
    }
}

@include media--md {

    .nav-container {
        display: block;
        min-height: $height--desktop-header - 10;
        position: static;

        .page--index &, .page--contest & {
            background-color: transparent;
            min-height: auto;
        }

        .menu-open & {
            display: block;
            min-height: 0;
        }

        &__outer-container {
            min-height: $height--desktop-header - 10;
            // overflow-y: auto;
            // position: static;
            width: auto;

            .page--index &, .page--contest & {
                display: none;
            }
        }

        &__mobile {
            overflow-y: auto;
            position: static;
            width: auto;
        }

    }
}

@include media--lg {

    .nav-container {

        min-height: $height--desktop-header;

        &__outer-container {
            min-height: $height--desktop-header;
        }

    }
}
