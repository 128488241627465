 .ui-datepicker {
     background-color: $color--white;     
     margin-top: 5px;        
}

.ui-datepicker a {}

.ui-datepicker table {
    width: 100%;
}

.ui-datepicker-header {
    color: $color--white;
    background-color: $color--black-90;
    height: 50px;   
    border-left: 2px solid $color--black-90;  
    border-top: 2px solid $color--black-90;
    border-right: 2px solid $color--black-90;
}

.ui-datepicker-title {
    text-align: center;
    width: auto;
    padding: 15px 0px;
    font-size: 20px;
}

.ui-datepicker-prev, .ui-datepicker-next {
    display: inline-block;    
    width: 18px;
    height: 30px;
    margin-top: 10px;
    cursor: pointer;
}

.ui-datepicker-prev {
    background-image: url(#{$path--img}calendar-arrow-left.png);
    background-repeat: no-repeat;
    float: left;    
    margin-left: 10px;    
}

.ui-datepicker-next {
    float: right;
    background-image: url(#{$path--img}calendar-arrow-right.png);
    background-repeat: no-repeat;
    margin-right: 10px;
}

.ui-datepicker thead {
    color: $color--black-90;
    background-color: $color--black-25;
    height: 35px;
    border-bottom: 1px solid $color--black-90;
    border-left: 2px solid $color--black-90;
    border-right: 2px solid $color--black-90;
}

.ui-datepicker th {
    text-align: center;
    vertical-align: middle;
    height: 35px;
    width: 38px;
}

.ui-datepicker tbody td {
    text-align: center;
    vertical-align: middle;
    height: 35px;
    border-right: 1px solid $color--black-90;
}

.ui-datepicker tbody td:first-child {    
    border-left: 2px solid $color--black-90;
}

.ui-datepicker tbody td:last-child {    
    border-right: 2px solid $color--black-90;
}

.ui-datepicker tbody tr {
    border-bottom: 1px solid $color--black-90;
}

.ui-datepicker tbody tr:last-child {
    border-bottom: 2px solid $color--black-90;    
}

.ui-datepicker td span, .ui-datepicker td a {
    color: $color--black-90;    
}

.ui-datepicker-calendar .ui-state-default {}

.ui-datepicker-unselectable .ui-state-default {}

.ui-datepicker-calendar .ui-state-hover {}

.ui-datepicker-calendar .ui-state-active {   
    color: $color--white;
}

.ui-datepicker-current-day {
    background-color: $color--black-90;
}

.ui-datepicker-today {
    background-color: $color--black-90;    
    color: $color--white !important;

    a, span {
        color: $color--white !important;
    }
}

.ui-datepicker-calendar td:first-child .ui-state-active {}

.ui-datepicker-calendar td:last-child .ui-state-active {}

.ui-datepicker-calendar tr:last-child .ui-state-active {}

.ui-datepicker-other-month {
    background-color: $color--light-gray-2;
}

.ui-datepicker-other-month a {
    color: $color--white !important;
}

*::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color--black-25 !important;
    text-transform: uppercase;
}
*:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: $color--black-25 !important;
   opacity:  1;
   text-transform: uppercase;
}
*::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: $color--black-25 !important;
   opacity:  1;
   text-transform: uppercase;
}
*:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: $color--black-25 !important;
   text-transform: uppercase;
}


@include media--md {
    
}