.main {

    margin-top: 20px;
    min-height: 700px;
    padding-bottom: 60px;
    position: relative;

    &--article {
        padding-top: 60px;
    }

    &--contest {
        margin-top: 0;
    }

    &__title {
        color: $color--white;
        font-size: $font-size--xxxl;
        font-weight: 300;
        margin-bottom: 20px;
        margin-top: 60px;
    }

    &__subtitle {
        color: $color--white;
        font-size: $font-size--lg;
        font-weight: 300;
        margin-bottom: 30px;
    }

    &__logo-container {
        margin: 20px 0;
    }
}

@include media--md {
    .main {

        &--index {
            align-items: center;
            display: flex;
            flex-flow: column;
            justify-content: center;
        }

        &--dashboard {
            padding-top: 60px;
        }

        &__title {
            // font-size: $font-size--xxl;
            margin-bottom: 20px;
            margin-top: 0;
        }

        &__subtitle {
            font-size: $font-size--xl;
            margin-bottom: 60px;
        }

        &__logo-container {
            bottom: $padding--md;
            margin: 0;
            position: absolute;
            right: $padding--md;
        }

        &--contest {
            margin-top: -60px;
        }
    }
}

body.page--contest {

    .main {
        background-color: $color--white;
        background-image: url(#{$path--img}bg-contest.jpg);
        background-repeat: no-repeat;
        position: relative;
        background-size: cover;
        margin-bottom: 0;
        margin-top: -60px;
        padding-top: 40px;
    }

}

body.page--contest-activate {

    .main {
        background-color: $color--white;
        background-image: url(#{$path--img}bakgrundsbild-blue.jpg);
        background-repeat: no-repeat;
        position: relative;
        background-size: cover;
        margin-top: -20px;
        padding-top: 40px;
        margin-bottom: -30px;
    }
}


body.page--contest-create {
    .main {
        margin-top: -60px;
    }
}

