.hover {

    &--red:hover {
        background-color: $color--red;
        border-color: $color--red;
        color: $color--white;
    }

    &--blue:hover {
        background-color: $color--blue;
        border-color: $color--blue;
        color: $color--white;
    }

    &--black-100:hover {
        background-color: $color--black-100;
        border-color: $color--black-100;
        color: $color--white;
    }

    &--light-gray:hover {
        background-color: $color--light-gray;
        border-color: $color--light-gray;
        color: $color--white;
    }

    &--purple:hover {
        background-color: $color--purple;
        border-color: $color--purple;
        color: $color--white !important;
    }

    &--purple-90:hover {
        background-color: $color--purple-90;
        border-color: $color--purple-90;
        color: $color--white;
    }
}
