.page {

    margin-top: 65px;
    padding-bottom: 60px;
    // padding-top: 45px;
    position: relative;

    &--index {
        background-color: $color--blue;
    }

    &--article {
        background-color: $color--white;
    }

    &--contest {
        background-color: $color--white;
        background-image: url(#{$path--img}bg-contest.jpg);
        background-repeat: no-repeat;
        position: relative;
        background-size: cover;
    }

}

@include media--md {
    .page {

        margin-top: 0;

        &--index {
            background-color: $color--white;
            background-image: url(#{$path--img}bg-index.png);
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
        }
    }
}
