.dashboard {

    background-color: $color--white;
    margin-bottom: 20px;

    &--settings {
        padding: 60px 0;
        text-align: center;
    }

    &__header {
        border-bottom: 1px solid $color--light-gray;
        padding: 13px 60px 13px 10px;
        position: relative;
        text-align: left;
        z-index: $z-index--dashboard-header;
    }

    &__main {
        padding-top: 20px;
        position: relative;
    }

    &__footer {
        align-items: space-between;
        display: flex;
        justify-content: center;
        min-height: 45px;
        padding: 10px;
        text-align: center;
    }

    &__footer--border {
        border-top: 1px solid $color--light-gray;
    }

    &__loader-container {
        background-color: $color--white;
        display: flex;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: $z-index--loader;
    }

    &__loader-container--hidden {
        animation: hide .4s forwards;
    }

    &__note {
        font-size: $font-size--sm;
        margin-bottom: 0;
    }

    &__data-container {
        align-items: center;
        display: flex;
        flex-flow: column;
        justify-content: center;
        min-height: 150px;
        position: relative;
        // width: 100%;
    }

    &__data-container--wide {
        width: 100%;
    }

    // &__data-container--has-subdata {
    //     justify-content: flex-end;
    // }

    &__data {
        display: block;
    }

    &__data--big {
        font-size: $font-size--xxxl + 2;
        font-weight: 700;
    }

    &__data--blue {
        color: $color--blue;
    }

    &__data--yellow {
        color: $color--yellow;
    }

    &__data--green {
        color: $color--green;
    }

    &__data--orange {
        color: $color--orange;
    }

    &__data--purple {
        color: $color--purple;
    }

    &__data--kr {
        position: relative;
    }

    &__hasindicator {
        position: relative;
    }

    &__indicator {
        font-size: $font-size--xs;
        position: absolute;
        right: -10px;
        top: 0;
        white-space: nowrap;
        width: 0;

        &::before {
            content: '';
            display: inline-block;
            height: 15px;
            margin-right: 5px;
            position: relative;
            top: 5px;
            width: 15px;
        }
    }

    &__indicator--big {
        top: 6px;
    }

    &__indicator--up {
        color: $color--green;

        &::before {
            @extend .bg--arrow-indicator;
        }
    }

    &__indicator--down {
        color: $color--red;

        &::before {
            @extend .bg--arrow-indicator;
            transform: rotate(90deg);
        }
    }

    &__subdata {
        display: inline-block;
        font-size: $font-size--xxxl;
        font-weight: 700;
    }

    &__subdata-container {
        border-top: 1px solid $color--light-gray;
        margin-top: 20px;
        text-align: center;
        width: 100%;
    }

    &__subheader {
        color: $color--gray;
        font-size: $font-size--sm;
        text-transform: uppercase;
    }

    &__title {
        font-size: $font-size--xl;
        line-height: 1;
    }

    &__info-button {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    &__cta {
        margin-top: 20px;
    }

    &__align-left {
        display: inline-block;
        text-align: left;
    }

    .suggestions-container {
        margin: 0px 30px 0px 30px;
        height: 280px;
    }
}

@include media--md {

    .dashboard {
        // padding-left: 90px;
        &--settings {
            padding: 60px 90px 50px;
            text-align: left;
        }

        &__main {
            padding-top: 0;
        }

        &__header {
            align-items: center;
            display: flex;
            justify-content: center;
            min-height: 60px;
            padding: 13px 10px;
            // text-align: center;
        }

        &__separator {
            position: relative;

            &::after {
                border-right: 1px solid $color--light-gray;
                content: '';
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        &__data-container {
            min-height: $height--data-container;
        }

        &__data--big {
            font-size: $font-size--xxxxl;
        }

        &__title {
            font-size: $font-size--xxl;
        }

        &__info-button {
            right: 20px;
        }
    }

    .floatRight {
        margin-left: 20px;
    }
}
