.c3-chart-arcs-title {
    dominant-baseline: middle;
    fill: $color--orange;
    font-size: $font-size--xxxl;
}

.chart {

    overflow: hidden;
    padding: 0 10px 20px 0;
    position: relative;
    text-align: center;
    width: 100%;

    &--error {
        align-items: center;
        color: $color--red;
        display: flex;
        justify-content: center;
    }

    svg {
        font-size: $font-size;
        -webkit-tap-highlight-color: transparent;
    }

    line,
    path {
        stroke: $color--light-gray;
    }

    .c3-axis line,
    .c3-axis path {
        fill: $color--gray;
        stroke: $color--gray;
    }

    .arrow line,
    .arrow path {
        stroke: $color--gray;
    }

    .c3-chart path {
        fill: none;
    }

    .c3-line {
        stroke-width: 3px;
    }

    .c3-chart-texts {
        font-size: $font-size--xs;
        font-weight: 700;
    }

    .c3-bar-0 {
        fill-opacity: .4;
        stroke-opacity: 0;

        // &._expanded_ {
        //     fill-opacity: 1;
        // }
    }


    .tick {

        line {
            display: none;
        }

        text {
            fill: $color--gray;
        }
    }

    .c3-target.c3-focused {
        opacity: 1;
    }

    &.chart--bar .c3-xgrid-focus {
        display: none;
    }

    // .c3-axis-x path,
    // .c3-axis-x line {
    //     stroke: $color--gray;
    // }

    // .c3-axis-y path,
    // .c3-axis-y line {
    //     stroke: $color--gray;
    // }

    .c3-axis-y .tick {
        display: none;
    }

    .c3-tooltip {
        // background-color: $color--white;
        background-color: $color--dark-blue;
        border-collapse: collapse;
        border-radius: 3px;
        border-spacing: 0;
        box-shadow: 5px 5px 5px 0 rgba($color--black, .1);
        color: $color--white;
        empty-cells: show;
        min-width: 130px;
        opacity: .9;

        .name {
            display: none;
        }

        th {
            border-radius: 3px;
            // color: $color--white;
            font-size: 14px;
            padding: 5px 10px;
        }

        td {
            background-color: $color--white;
            border-radius: 0 0 3px 3px;
            color: $color--black;
            font-size: 13px;
            padding: 5px 10px;
        }

        span {
            display: inline-block;
            height: 10px;
            margin-right: 6px;
            width: 10px;
        }
    }
}

@include media--md {
    .chart {
        padding: 40px 60px 20px 50px;
    }
}
