.form {

    text-align: left;

    .field-validation-error {
        color: $color--red;
        position: relative;
        top: -15px;
    }

    &--login {
        background-color: $color--white;
        color: $color--black-100;
        padding: 20px;
        width: 100%;
    }

    &--admin {
        padding-bottom: 70px;
    }

    &__logo-container {
        display: none;
    }

    &__header {
        // padding: 60px 0 50px;
        padding-bottom: 50px;
    }

    &__table {
        display: table;
        margin-bottom: 25px;
    }

    &__row {
        display: table-row;
    }

    &__cell {
        display: table-cell;
        padding-bottom: 10px;
        padding-right: 10px;
        vertical-align: middle;
    }

    &__title {
        color: $color--black-100;
        font-size: $font-size--xxl;
        margin-bottom: 0;
    }

    &__label {
        color: $color--black-40;
        display: block;
        font-size: 1.4rem;
        font-weight: 300;
        margin-bottom: 7px;
    }

    &__info {
        font-weight: 300;
    }

    &__text {
        border: 2px solid $color--black-20;
        border-radius: 4px;
        color: $color--black-80;
        display: block;
        font-family: MuseoSans;
        font-size: $font-size--sm;
        font-weight: 300;
        height: 43px;
        line-height: 1.43;
        margin-bottom: 25px;
        outline: 0;
        padding: 0 15px;
        width: 100%;

        &.input-validation-error {
            border: 2px solid $color--red;
            color: $color--red;
        }
    }

    &__checkbox {
        display: none;
    }

    &__checkboxlabel {
        @extend .bg--checkbox;
        display: inline-block;
        height: 30px;
        width: 30px;
    }

    &__checkbox:checked + &__checkboxlabel {
        @extend .bg--checkbox-checked;
    }

    &__radio {
        display: none;
    }

    &__radiolabel {
        @extend .bg--radio;
        display: inline-block;
        height: 27px;
        width: 27px;
    }

    &__radio:checked + &__radiolabel {
        @extend .bg--radio-checked;
    }

    &__select {
        display: block;
        position: relative;

        &:after {
            background-position: center;
            bottom: 0;
            content: '';
            height: 100%;
            pointer-events: none;
            position: absolute;
            right: 0;
            width: 35px;
        }

        select {
            background-color: $color--white;
            border: none;
            color: $color--blue;
            font-family: MuseoSans;
            font-size: $font-size--lg;
            font-weight: 300;
            // Padding order is important
            padding-right: 15px;
            -webkit-padding-end: 35px;
            width: 100%;
        }
    }

    // &__select--white {
    //     &:after {
    //         @extend .bg--arrow-down-white;
    //         background-color: transparent;
    //     }
    // }

    &__select--blue {
        &:after {
            @extend .bg--arrow-down-blue;
            background-color: $color--white;
        }
    }

    &__select--greybg {
        &:after {
            @extend .bg--arrow-down-blue;
            background-color: $color--lighter-gray;
        }

        select {
            background-color: $color--lighter-gray;
        }
    }

    &__utils {
        color: $color--blue;
        display: block;
        margin-top: 30px;
        text-align: center;
        text-transform: uppercase;
    }

    &__textarea {
        width: 100%;
        display: block;
        resize: none;
        border: 2px solid $color--black-20;
        border-radius: 4px;
        font-family: MuseoSans;
        font-size: $font-size--sm;
        font-weight: 300;
        height: 200px;
        line-height: 1.43;
        margin-bottom: 25px;
        outline: 0;
        padding: 10px;
    }
}

@include media--md {
    .form {

        &--login {
            padding: 20px;
            width: 390px;
        }

        &--admin {
            max-width: $width--md;
        }

        &__logo-container {
            display: block;
            margin-bottom: 30px;
        }

        &__title {
            font-size: $font-size--xxxl;
            line-height: 50px;
        }

        &__label {
            font-size: 1.7rem;
        }

        &__text {
            font-size: 1.7rem;
            height: 52px;
        }

        &__select {
            display: inline-block;

            select {
                width: auto;
            }
        }
    }
}
