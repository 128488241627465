.nav-handle {

    @extend .bg--nav-handle;
    color: $color--dark-blue;
    cursor: pointer;
    height: 25px;
    position: absolute;
    right: 0;
    top: 10px;
    width: 25px;

    .menu-open & {
        @extend .bg--nav-close;
    }

    .splashpage & {
        display: none;
    }
}

@include media--md {
    .nav-handle {
        display: none;
    }
}

