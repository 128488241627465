.main-footer {

    background-color: $color--dark-blue;
    color: $color--white;
    margin-top: 30px;
    padding: 40px 0;
    position: relative;
    z-index: $z-index--main-footer;

    h2 {
        color: $color--white;
        font-size: $font-size--xl;
        font-weight: 500;
        letter-spacing: -.5px;
        line-height: 5rem;
    }

    p,
    a {
        color: $color--bone-white;
        font-size: 1.7rem;
        line-height: 2.8rem;
        margin-bottom: 0;
    }

    p + h2 {
        margin-top: 10px;
    }

    &__area {
        margin-bottom: 40px;
        padding-right: 20px;
    }

    &__logo-container {
        vertical-align: bottom;

        a {
            text-decoration: none;
        }
    }

    &__slogan {
        background-position: left bottom;
        background-repeat: no-repeat;
        height: 150px;
        width: 100%;
    }

    &__logo {
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: contain;
        height: auto;
        margin-top: 20px;
        width: 100%;
    }

    &__link-item {
        float: left;
    }

    &__links {
        list-style: none;
        margin-bottom: 40px;
        margin-left: 0;
    }

    &__link {
        background-position: left center;
        background-size: contain;
        display: block;
        margin-bottom: 20px;
        margin-right: 10px;

        &:hover {
            text-decoration: none;
        }
    }

    // Styles to fix import of footer
    .u-confine {
        margin: 0 auto;
        max-width: $width--max + $padding * 2;
        padding-left: $padding;
        padding-right: $padding;
    }

    .u-container {
        width: 100%;

        &--clearfix:before {
            content: '';
            display: block;
        }

        &--clearfix:after {
            clear: both;
            content: '';
            display: table;
        }

        &--no-wrap {
            display: table;
        }
    }

    .u-g-12-12 {
        float: left;
        width: 100%;
    }
}

@include media--md {
    .main-footer {
        &__links {
            margin-top: 40px;
        }

        &__link {
            margin-bottom: 0;
        }

        // Styles to fix import of footer
        .u-confine {
            max-width: $width--max + $padding--md * 2;
            padding-left: $padding--md;
            padding-right: $padding--md;

            &--no-padding {
                max-width: $width--xl;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .u-g-3-12--m {
            float: left;
            width: 25%;
        }

        .u-g-4-12--m {
            float: left;
            width: 33.3333%;
        }
    }
}

body.page--contest {
    .main-footer {
        margin-top: 0;
    }
}

@include media--lg {
    .main-footer {

        &__links {
            margin-bottom: 0;
        }

        &__link {
            height: 60px;
            margin-right: 50px;
        }

        // Styles to fix import of footer
        .u-confine {
            max-width: $width--max + $padding--lg * 2;
            padding-left: $padding--lg;
            padding-right: $padding--lg;

            &--no-padding {
                max-width: $width--xl;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .u-tg-9-12--l {
            display: table-cell;
            width: 75%;
        }

        .u-tg-3-12--l {
            display: table-cell;
            width: 25%;
        }

        .u-o-6-12--l {
            margin-left: 50%;
        }
    }
}
