.nav-top {

    background-color: $color--dark-blue;
    color: $color--white;
    height: 45px;
    // padding: 12px 0 0;

    &__link {
        color: $color--white;
        font-size: $font-size;
        font-weight: 500;
        letter-spacing: .5px;
        line-height: 45px;
        margin: 0 10px;
        padding: 15px 0 0;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;

        &:after {
            background-color: $color--red;
            content: '';
            height: 0;
            left: 0;
            position: absolute;
            top: 0;
            transition: height 200ms;
            width: 100%;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            margin: 0;
        }

        &:hover {
            opacity: 1;
            text-decoration: none;
        }

        &:hover:after {
            height: 3px;
        }

        &--active:after {
            height: 3px;
        }
    }
}

@include media--md {
    .nav-top {
        height: 30px;
        left: 0;
        // padding: 6px 0 0;
        position: absolute;
        text-align: left;
        top: 0;
        width: 100%;

        &__link {
            font-size: $font-size--xs;
            line-height: 30px;
            margin: 0 15px 0 0;
            opacity: .8;
            padding: 9px 0 0;

            &--active {
                opacity: 1;
            }
        }
    }
}
