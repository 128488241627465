.btn {

    background-color: $color--white;
    border: 1px solid $color--gray;
    border-radius: 2px;
    color: $color--gray;
    cursor: pointer;
    display: block;
    font-family: MuseoSans;
    height: 28px;
    text-align: center;
    transition: all 200ms;

    &:hover {
        text-decoration: none;
    }

    &:focus,
    &:active {
        outline: 0;
    }

    &--active {
        background-color: $color--black-100;
        border-color: $color--black-100;
        color: $color--white;
    }

    &--standard {
        width: 100%;
    }

    &--control {
        font-size: 1.1rem;
        line-height: 28px;
        margin: 0 5px;
        text-transform: uppercase;
    }

    &--small {
        font-weight: 300;
        height: 36px;
        line-height: 34px;
        padding: 0 70px;
    }

    &--big {
        border-radius: 4px;
        font-size: $font-size--xl;
        font-weight: 500;
        height: 52px;
        padding: 0 70px;
        width: 100%;
    }

    &--round {
        border-radius: 50%;
        display: inline-block;
        line-height: 28px;
        text-align: center;
        width: 28px;
    }

    &--rounded {
        border: 2px solid $color--gray;
        border-radius: 56px;
        font-size: $font-size--sm;
        font-weight: 500;
        height: 36px;
        letter-spacing: 1.5px;
        line-height: 34px;
        padding: 0 30px;
        text-transform: uppercase;
    }

    &--small-rounded {
        border: 1px solid $color--gray;
        border-radius: 56px;
        font-weight: 300;
        height: 36px;
        line-height: 36px;
        padding: 0 30px;
    }

    &--blue {
        background-color: $color--blue;
        border-color: $color--blue;
        color: $color--white;
    }

    &--purple {
        background-color: $color--purple;
        border-color: $color--purple;
        color: $color--white;
    }

    &--navy-blue {
        background-color: $color--navy-blue;
        border-color: $color--navy-blue;
        color: $color--white;
    }

    &--wide {
        width: 100%;
    }

    &--narrow {
        padding-left: 40px;
        padding-right: 40px;
    }

    &--diabled {
        opacity: .4;
    }
}

@include media--md {

    .btn {
        display: inline-block;

        &--standard {
            width: 84px;
        }

        &--big {
            width: auto;
        }

        &--rounded {
            height: 46px;
            line-height: 44px;
        }
    }
}
