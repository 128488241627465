.loader {

    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;

    &__bar {
        animation: loader-stretch 1.2s infinite ease-in-out;
        background-color: $color--light-gray;
        display: inline-block;
        height: 50px;
        margin-left: 2px;
        margin-right: 2px;
        width: 6px;
    }

    &__bar--2 {
        animation-delay: -1.1s;
    }

    &__bar--3 {
        animation-delay: -1s;
    }

    &__bar--4 {
        animation-delay: -.9s;
    }

    &__bar--5 {
        animation-delay: -.8s;
    }
}
