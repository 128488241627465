.nav-main {

    &__links {
        margin-bottom: 15px;
        margin-top: 15px;
    }

    &__link {
        color: $color--black-100;
        display: block;
        font-size: $font-size--lg;
        font-weight: 300;
        line-height: 1.6;
        margin: auto $padding;
        text-decoration: none;

        &--active {
            color: $color--red;
        }
    }
}


@include media--md {

    .nav-main {

        &__links {
            float: right;
            margin-bottom: 0;
            margin-top: 0;
        }

        &__link {
            float: left;
            margin: auto 0;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 30px;
            position: relative;
            text-decoration: none;

            &:after {
                background-color: $color--red;
                content: '';
                height: 0;
                left: 0;
                position: absolute;
                top: 0;
                transition: height 200ms;
                width: 100%;
            }

            &:hover {
                color: $color--red;
                text-decoration: none;
            }

            &:hover:after {
                height: 5px;
            }

            &--active:after {
                height: 5px;
            }
        }
    }
}

@include media--lg {
    .nav-main {
        &__link {
            padding-top: 30px;
        }
    }
}
