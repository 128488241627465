.transactions {    
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    .datepicker {             
        border-radius: 4px;
        color: purple; 
        text-indent: 5px; 
        line-height: 2;  
        font-size: 1.6rem;   
        position: relative;
        z-index: 100000;   
        cursor: pointer;
    }
    
    input {                
        width: 100%;
        margin: 10px auto;                        
        border: 2px purple solid;   
        display: block;          
    }      
    .black-100 {
        color: $color--black-100;
    }       
}

.transactions__subheader {
    padding-bottom: 40px;
}

.transactions__input {
    padding-bottom: 30px;
}
.transactions__buttons {
    padding-bottom: 23px;
}

@include media--md {
    .transactions {
        .float-right {
            float: right;
        }
        .float-left {
            float: left
        }

        .datepicker {
            width: 90%;
            max-width: 275px;
        }

        input {            
            margin: 20px 5px;
            display: block;            
            width: auto;
        }
    }
}

.transactions {
    .table {
        font-size: $font-size--xl;
        // min-width: $width--md;
        table-layout: fixed;
        width: 100%;

        &__row {

            &:hover,
            &:nth-child(odd):hover {
                background-color: $color--lighter-blue;
            }

            &:nth-child(odd) {
                background-color: $color--cream;
            }
        }

        &__row--header {
            background-color: transparent;

            &:hover,
            &:nth-child(odd),
            &:nth-child(odd):hover {
                background-color: transparent;
            }
        }

        &__cell {
            // border-right: 1px solid $color--light-gray;
            display: none;
            font-weight: 300;
            padding: 10px;
            text-align: left;

            &:first-child {
                display: table-cell;
                border-right: 1px solid $color--light-gray;
            }

            &:nth-child(2) {
                    display: table-cell;
                }

            &:last-child {
                border-right: none;
            }
        }

        &__cell--header {
            border-bottom: 1px solid $color--light-gray;
            // border-right: 1px solid $color--light-gray;
            color: $color--black-100;
            font-weight: 700;
            padding: 10px;
        }

        &__cell--center {
            text-align: center;
        }

        &__text--green {
            color: $color--green;
        }

        &__text--red {
            color: $color--red;
        }

        &__icon {
            background-position: left calc(50% - 2px);
            display: inline-block;
            padding-left: 28px;
        }

        &__icon--notice {
            @extend .bg--cross-red;
            background-size: 11px;
            color: $color--red;
        }

        &__icon--checked {
            @extend .bg--check-green;
            background-size: 15px;
            color: $color--gray;
        }

        &__pagination {
            margin: auto;
            width: 100%;
            padding-top: 50px;
            text-align: center !important;
        }

        &__pagination--page {
            padding: 5px;
            display: inline-block;        
        }

        &__pagination--page--current {
            padding: 5px;
            display: inline-block;        
            text-decoration: underline;        
        }
    }

    @include media--sm {
        .table {
            &__cell {
                border-right: 1px solid $color--light-gray;

                

                &:nth-child(3) {
                    display: table-cell;
                    border-right: 0;
                }
            }

            &__cell--header {
                border-right: 1px solid $color--light-gray;
            }
        }
    }

    @include media--md {
        .table {
            width: 100%;

            &__cell {
                padding: 20px;

                &:nth-child(3) {                    
                    //border-right: 1px solid $color--light-gray;
                }
                
                &:nth-child(4) {
                    //display: table-cell;
                }
            }

            &__cell--header {
                padding: 10px 20px;
            }
        }
    }

    @include media--lg {
        .table {

            &__cell {

                
            }
        }
    }
}