* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba($color--black, 0);
}

*:focus {
    outline: 0;
}

html {
    font-size: 62.5%;
    height: 100%;
}

body {
    background-color: $color--lighter-gray;
    color: $color--gray;
    font-family: MuseoSans;
    font-size: $font-size--md;
    // -moz-osx-font-smoothing: grayscale;
    // -webkit-font-smoothing: antialiased;
    height: 100%;
    overflow-x: hidden;
    // text-rendering: optimizeLegibility;
}

input,
select {
    -webkit-appearance: none;
}

p {
    color: $color--black-90;
    font-size: $font-size--md;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 20px;
}

ol {
    list-style: decimal;
    margin-bottom: 20px;
    margin-left: 15px;
}

ul {
    list-style: disc;
    margin-bottom: 20px;
    margin-left: 15px;
}

li {
    color: $color--black-90;
    font-size: $font-size--md;
    font-weight: 300;
    line-height: 1.5;
}

a {
    color: $color--navy-blue;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

h1 {
    color: $color--black-90;
    font-size: $font-size--xxxl;
    font-weight: 500;
    line-height: 3.6rem;
    margin-bottom: 40px;
}

h2 {
    color: $color--black-90;
    font-size: $font-size--xxl;
    font-weight: 500;
    line-height: 3.6rem;
}

h3 {
    color: $color--black-90;
    font-size: $font-size--xl;
    font-weight: 500;
    line-height: 3.6rem;
}

strong {
    font-weight: 700;
}
