.logo {

    background-size: contain;
    display: inline-block;

    &--header {
        @extend .bg--logo;
        height: 30px;
        width: 220px;
    }

    &--mono {
        @extend .bg--logo-mono;
        height: 30px;
        width: 145px;
    }

    &--mono-white {
        @extend .bg--logo-mono-white;
        height: 30px;
        width: 145px;
    }
}

@include media--md {

    .logo {
        &--header {
            height: 30px;
            width: 165px;
        }
    }
}

@include media--xl {
    .logo {
        &--header {
            height: 40px;
            width: 220px;
        }
    }
}
