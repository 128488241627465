// Paths
$path--img: '/Static/Builds/img/';
$path--font: '/Static/Builds/fonts/';

// Widths & heights
$width--xs: 320px;
$width--sm: 480px;
$width--md: 960px;
$width--lg: 1024px;
$width--xl: 1310px;
$width--max: 2100px;
$height--mobile-header: 45px;
$height--desktop-header: 90px;
$height--data-container: 280px;

// Padding and margins
$padding: 10px;
$padding--md: 20px;
$padding--lg: 60px;
$padding--xl: 60px;

// Colors
$color--white: #ffffff;
$color--cream: #fafafb;
$color--bone-white: #cfd2db;
$color--black: #000000;
$color--black-100: #3c445e;
$color--black-90: #3c445e;
$color--black-80: #515970;
$color--black-70: #686e82;
$color--black-60: #7d8294;
$color--black-50: #9297a5;
$color--black-40: #a8acb7;
$color--black-30: #bdc0c9;
$color--black-25: #c4c7ce;
$color--black-20: #d4d5db;
$color--black-10: #e9eaed;
$color--black-1: #f4f4f6;
$color--blue: #338dc7;
$color--light-blue: #c2ddef;
$color--lighter-blue: #e2eff7;
$color--dark-blue: #111f4c;
$color--navy-blue: #0070b9;
$color--red: #dc291a;
$color--red--light: #f8d4d1;
$color--gray: #747a8d;
$color--medium-gray: #d4d5db;
$color--light-gray: #e5e6e9;
$color--light-gray-2: #ebecef;
$color--lighter-gray: #f4f4f6;
$color--green: #00926e;
$color--purple: #93408e;
$color--purple-90: #a34a9e;
$color--yellow: #eb9f32;
$color--orange: #ea6649;


// Font sizes
$font-size: 1rem;
$font-size--xs: 1.2rem;
$font-size--sm: 1.4rem;
$font-size--md: 1.6rem;
$font-size--lg: 1.8rem;
$font-size--xl: 2rem;
$font-size--xxl: 2.5rem;
$font-size--xxxl: 4rem;
$font-size--xxxxl: 10rem;

// Z-index
$z-index--1: 100;
$z-index--2: 200;
$z-index--3: 300;
$z-index--4: 400;
$z-index--5: 500;
$z-index--6: 600;
$z-index--7: 700;
$z-index--8: 800;
$z-index--9: 900;
$z-index--10: 1000;
$z-index--header-main: $z-index--6;
$z-index--main-footer: $z-index--1;
$z-index--header-main-logo: ($z-index--header-main + 1);
$z-index--nav-container: ($z-index--header-main - 1);
$z-index--main-menu: ($z-index--header-main - 1);
$z-index--usp: $z-index--5;
$z-index--loader: $z-index--usp - 1;
$z-index--dashboard-header: $z-index--1;
