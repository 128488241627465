@font-face {
    font-family: 'MuseoSans';
    src: url('#{$path--font}2C5020_0_0.eot');
    src: url('#{$path--font}2C5020_0_0.eot?#iefix') format('embedded-opentype'),
    url('#{$path--font}2C5020_0_0.woff2') format('woff2'),
    url('#{$path--font}2C5020_0_0.woff') format('woff'),
    url('#{$path--font}2C5020_0_0.ttf') format('truetype'),
    url('#{$path--font}2C5020_0_0.svg#wf') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('#{$path--font}2C5020_1_0.eot');
    src: url('#{$path--font}2C5020_1_0.eot?#iefix') format('embedded-opentype'),
    url('#{$path--font}2C5020_1_0.woff2') format('woff2'),
    url('#{$path--font}2C5020_1_0.woff') format('woff'),
    url('#{$path--font}2C5020_1_0.ttf') format('truetype'),
    url('#{$path--font}2C5020_1_0.svg#wf') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('#{$path--font}2C5020_2_0.eot');
    src: url('#{$path--font}2C5020_2_0.eot?#iefix') format('embedded-opentype'),
    url('#{$path--font}2C5020_2_0.woff2') format('woff2'),
    url('#{$path--font}2C5020_2_0.woff') format('woff'),
    url('#{$path--font}2C5020_2_0.ttf') format('truetype'),
    url('#{$path--font}2C5020_2_0.svg#wf') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('#{$path--font}2C5020_3_0.eot');
    src: url('#{$path--font}2C5020_3_0.eot?#iefix') format('embedded-opentype'),
    url('#{$path--font}2C5020_3_0.woff2') format('woff2'),
    url('#{$path--font}2C5020_3_0.woff') format('woff'),
    url('#{$path--font}2C5020_3_0.ttf') format('truetype'),
    url('#{$path--font}2C5020_3_0.svg#wf') format('svg');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('#{$path--font}2C5020_4_0.eot');
    src: url('#{$path--font}2C5020_4_0.eot?#iefix') format('embedded-opentype'),
    url('#{$path--font}2C5020_4_0.woff2') format('woff2'),
    url('#{$path--font}2C5020_4_0.woff') format('woff'),
    url('#{$path--font}2C5020_4_0.ttf') format('truetype'),
    url('#{$path--font}2C5020_4_0.svg#wf') format('svg');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('#{$path--font}2C5020_5_0.eot');
    src: url('#{$path--font}2C5020_5_0.eot?#iefix') format('embedded-opentype'),
    url('#{$path--font}2C5020_5_0.woff2') format('woff2'),
    url('#{$path--font}2C5020_5_0.woff') format('woff'),
    url('#{$path--font}2C5020_5_0.ttf') format('truetype'),
    url('#{$path--font}2C5020_5_0.svg#wf') format('svg');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('#{$path--font}2C5020_6_0.eot');
    src: url('#{$path--font}2C5020_6_0.eot?#iefix') format('embedded-opentype'),
    url('#{$path--font}2C5020_6_0.woff2') format('woff2'),
    url('#{$path--font}2C5020_6_0.woff') format('woff'),
    url('#{$path--font}2C5020_6_0.ttf') format('truetype'),
    url('#{$path--font}2C5020_6_0.svg#wf') format('svg');
    font-weight: 500;
    font-style: italic;
}
