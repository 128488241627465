.contest {
    display: flex;
    flex-flow: column;

    &__headline-container {
        text-align: center;
        position: relative;
        max-width: 560px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    &__headline {
        font-size: $font-size--xxxl;
        color: $color--white;
        text-transform: uppercase;
        font-weight: bold;
        text-shadow: -1px 3px 0 rgba(36,33,80, 0.7);
        text-anchor: middle;
    }

    &__svg-container {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        vertical-align: top;
        overflow: hidden;
        width: 100%;
    }

    &__svg {
        font-size: 3rem;
        color: $color--white;
        text-transform: uppercase;
        font-weight: bold;
        text-shadow: -1px 3px 0 rgba(36,33,80, 0.7);
        text-anchor: middle;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
    }

    &__airplane-container {
        width: 100%;
        max-width: 345px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    &__airplane-seperator {
        display: block;
        width: 20%;
        height: 1px;
        border-top: 1px solid #FFF;
    }

    &__airplane {
        width: 30%;
        height: 45px;
        display: block;
        @extend .bg--contest-airplane;
        background-position: center;

        &--corner {
            width: 85px;
            top: 50px;
            right: 20px;
            position: absolute;
            transform: rotate(-13deg);
        }
    }

    &__form {
        background-color: $color--white;
        color: $color--black-100;
        padding: 20px 10px;
        width: 100%;
        max-width: 560px;
        margin: 0 auto;
    }

    &__form-feedback {
        background-color: $color--white;
        color: $color--black-70;
        padding: 60px 10px;
        width: 100%;
        max-width: 560px;
        margin: 0 auto;
        margin-top: 100px;
        text-align: center;
    }

    &__thankyou {
        font-size: $font-size--xxxl;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
        font-family: MuseoSans;
    }

    &__thankyou-message {
        font-weight: 500;
        color: $color--black-70;
        font-family: MuseoSans;
        font-size: $font-size--xl;
        margin-bottom: 30px;
    }

    &__label {
        font-weight: 500;
        color: $color--black-100;
        margin-bottom: 10px;
    }

    &__radio-container {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    &__btn-container {
        text-align: center;
    }

    &__activate-preamble {
        color: white;
        max-width: 600px;
        margin-top: 20px;
    }


    &__block {
        background-image: url(#{$path--img}contest-block-bg.jpg);
        background-repeat: no-repeat;
        min-height: 380px;
        text-align: center;
        padding: 40px 20px;
    }

    &__rules {
        margin-top: 10px;
        font-size: $font-size--xs;
        font-weight: 300;
        line-height: 20px;
    }

    &__rules-toggle {
        cursor: pointer;
        display: block;
        width: 100%;
        text-align: center;

        &--hide {
            display: none;
        }
    }

    &__rules-container {
        margin-top: 10px;
        display: none;
    }

}

@include media--sm {

    .contest {
        &__svg {
            font-size: $font-size--xxl;
        }

        &__airplane-seperator {
            width: 35%;
        }

        &__airplane-container {
            margin-bottom: 10px;
        }
    }

}

@include media--md {

    .contest {
        &__form {
            padding: 40px;
        }

        &__rules {
            padding: 20px;
        }

        &__rules-container{
            margin-top: 0;
            padding: 20px;
        }

        &__airplane {
            &--corner {
                top: 20px;
            }
        }

        &__svg {
            font-size: $font-size--xxl;
        }
    }

}
